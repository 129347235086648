@use '../../../../../theme.scss' as t;

.row {
  & > td:first-child {
    position: relative;
    &::before {
      border-left: transparent 0.4rem solid;
      bottom: 0;
      content: ' ';
      display: block;
      left: -1px;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }
}

.success > td:first-child::before {
  border-color: t.$success;
}

.warning > td:first-child::before {
  border-color: t.$warning;
}

.danger > td:first-child::before {
  border-color: t.$danger;
}

.unset > td:first-child::before {
  border-color: t.$theme-unset;
}
