.mt8 {
  margin-top: 3rem !important;
}

.link {
  display: block;
  font-weight: bold;
  font-size: 0.9em;
  position: relative;
}

.joblistCol1 {
  min-width: 5em;
}

.total {
  color: #00e6dc;
}

.bigpill {
  color: #fff;
  font-size: 1.5em;
  margin-top: -32px;
  height: 65px;
  width: 65px;
}

.alertClose {
  color: #fff !important;
  opacity: 1;
  text-shadow: none;
}
