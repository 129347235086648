.closed {
  pointer-events: none;
}
.open {
  animation-name: reveal-tooltip;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
@keyframes reveal-tooltip {
  from {
  }
  to {
    opacity: 1;
  }
}

.openDelayed {
  animation-name: reveal-tooltip-delayed;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes reveal-tooltip-delayed {
  from {
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  width: 200px;
}
