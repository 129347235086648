@use '../../../../theme.scss' as t;

$border: t.$primary 1px solid !important;

.expandedStickyHead {
  position: -webkit-sticky;
  position: sticky;
  top: 99px;
  z-index: 2;
  padding-right: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: t.$table-accent-bg;
  &::before {
    border-top: $border;
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.expandedHeadMain {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
}

.expandedBody {
  border-bottom: $border;
  border-left: $border;
  border-right: $border;
}

.bgMuted {
  background-color: t.$table-accent-bg;
}

