.animation-wrapper {
  overflow: hidden;
}

.dropdown-enter {
  margin-top: -100vh;
}

.dropdown-enter-active {
  margin-top: 0;
  transition: 300ms ease;
}

.dropdown-exit {
  margin-top: 0;
}

.dropdown-exit-active {
  margin-top: -100vh;
  transition: 300ms ease;
}
