@use '../../../theme.scss' as t;

.dropdown {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -3px !important;
  padding-top: 0 !important;
}

.invalidDropdown {
  border-color: t.$danger !important;
}

.slotRight {
  color: t.$theme-input-label-color;
  position: absolute;
  right: 0.75rem;
  transform: translateY(50%);
  top: 0;
}

// TODO: .label and .focused are copy&paste in SelectBox, maybe it's worth consolidating?
.label {
  bottom: 0;
  color: t.$theme-input-label-color;
  pointer-events: none;
  left: 0.75rem;
  margin: 0;
  position: absolute;
  transform: translateY(-0.08rem);
  transform-origin: top left;
  top: 0.75rem;
}

.focused {
  animation-duration: 0.2s;
  animation-name: revealvalue;
  animation-fill-mode: forwards;
}

@keyframes revealvalue {
  from {
  }
  to {
    transform: translateY(-0.35rem) scale(0.6);
  }
}

.textarea {
  line-height: 2.4em !important;
  min-height: 3em !important;
}
