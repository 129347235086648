@use '../../../theme.scss' as t;

.label {
  color: t.$theme-pictogram-stroke;
  font-size: 2em;
  margin-top: -0.5em;
}

.icon {
  color: t.$theme-pictogram-stroke;
  font-size: 8em;
}

.iconBack {
  @extend .icon;
  opacity: 0.5;
}

.iconFront {
  @extend .icon;
  margin-left: 0.2em;
  margin-top: 0.2em;
}
