// Note that the way the docs tell you to create the theme doesn't work for some
// reason. The only way I got it to work is to define the complete theme upfront
// and then import bootstrap last. I don't know what is happening here, let's
// hope they do something with the "new" SASS module system someday.
// See https://getbootstrap.com/docs/4.6/getting-started/theming/

@import './theme.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

.navbar-custom-secondary {
  background: $theme-header-bg;
  margin-top: $theme-header-size;
  .breadcrumb {
    margin-bottom: 0;
  }
}
.navbar-custom-secondary-followup {
  margin-top: 167px;
}

// Customize components here

// We only overwrite this to have the white label color
.btn-secondary {
  @include button-variant($secondary, $secondary);
  color: #fff;
}

.btn-gray-custom {
  @include button-variant($gray-400, $gray-400);
  color: #fff;
}

.btn-link-primary-custom {
  font-weight: $font-weight-normal;
  color: $primary;
  text-decoration: $link-decoration;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

.alert-inquisitive {
  @include alert-variant($primary, $primary, #fff);
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.rounded-shadow-custom {
  border-radius: 0.6rem !important;
  box-shadow: 0 5px 10px 10px #f0f0f0 !important;
}

// Inspired by https://css-tricks.com/position-sticky-and-table-headers/
.card-custom-table {
  @extend .rounded-shadow-custom;
  border: 0;
  margin-top: 2.5rem;
  > .card-body {
    padding-bottom: 1.6 * $font-size-base;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    > * > table {
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
      > thead {
        height: 0;
        > tr {
          height: 0;
          > th {
            border-top-color: transparent;
            height: 0;
            padding-bottom: 0;
            padding-top: 0;
            position: relative;
            top: -2rem;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.form-control-select-custom {
  @extend .form-control;
  min-width: 25em;
  max-width: 25em;
  padding-right: 2em;
  position: relative;
  text-align: inherit;

  &::after {
    position: absolute;
    right: 1em;
    top: 45%;
  }
}

.table-striped-custom {
  > tbody > tr {
    border-left: transparent 1px solid;
    border-right: transparent 1px solid;
  }
  > tbody > tr:nth-of-type(4n + 1) {
    background-color: $table-accent-bg;
  }
  > tbody > tr:nth-of-type(4n + 2) {
    background-color: $table-accent-bg;
  }
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

.badge-primary-accent {
  @include badge-variant($theme-badge-primary-accent-bg);
  color: #fff;
  font-weight: normal;
}

.badge-company-source {
  @include badge-variant($dark);
  color: #fff;
  font-weight: normal;
}

.edit-form {
  background-color: $theme-edit-form-bg;
  //position: relative;
  //&::after {
  //  background: $primary;
  //  bottom: 0;
  //  content: ' ';
  //  display: block;
  //  left: 0;
  //  position: absolute;
  //  top: 0;
  //  width: 0.2rem;
  //}
}
//.edit-form-header {
//  background-color: $theme-edit-form-bg;
//  color: $primary !important;
//  position: relative;
//  &::after {
//    background: $primary;
//    bottom: 0;
//    content: ' ';
//    display: block;
//    left: 0;
//    position: absolute;
//    top: 0;
//    width: 0.2rem;
//  }
//}

.custom-switch {
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $theme-custom-switch-indicator-bg; // Added
    }
  }
}

.badge-wide-custom {
  min-width: 2.8em;
}

.table-layout-fixed {
  table-layout: fixed;
  width: 100%;
}

.overflow-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

#tooltip-portal {
  position: absolute;
  left: 0;
  top: 0;
}
