.handle {
  color: #000 !important;
  height: 1.5em !important;
}

.icon {
  height: 1em !important;
  margin-top: -0.4em !important;
}

.muted {
  opacity: 0.2 !important;
}
