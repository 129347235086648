@keyframes spinner-frames {
  0% {
    top: 37%;
    left: 37%;
    width: 18%;
    height: 18%;
    opacity: 1;
  }
  100% {
    top: 8%;
    left: 8%;
    width: 77%;
    height: 77%;
    opacity: 0;
  }
}

.spinner div {
  box-sizing: content-box;
  position: absolute;
  border-width: var(--spinner-border-size);
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: spinner-frames 1.9s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: spinner-frames 1.9s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spinner div:nth-child(1) {
  border-color: var(--primary);
}
.spinner div:nth-child(2) {
  border-color: var(--primary-active);
  -webkit-animation-delay: -0.95s;
  animation-delay: -0.95s;
}
.spinner {
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%) scale(1) translate(50%, 50%);
  display: flex;
  margin: auto;
}
