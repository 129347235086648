.BackdropSpinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.withBackdropSpinner {
  position: relative;
}
.withBackdropSpinner.loading > * {
  opacity: 0.4;
}
.withBackdropSpinner > .BackdropSpinner {
  opacity: 1;
}
