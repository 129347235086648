// Note that this needs to be defined before bootstrap is included because
// the SVGs are only built *once* when bootstrap is imported, variables set
// after the import aren't considered. Great system...

/** colors **/
$primary: #009898;
$secondary: #ff9000;
$info: $primary;

$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;
$dark: #333353;

$body-bg: #f7f7f7;

$table-accent-bg: #f7f7f7;

$input-placeholder-color: #aaaa96;

/** Overwrite for Bootstap custom checkbox color **/
$custom-control-indicator-checked-disabled-bg: #dfe6ed;
$custom-control-indicator-checked-bg: #fff; // $activeBlue
$custom-control-indicator-checked-color: #005578; // $activeBlue
$custom-control-indicator-border-color: #879baa;
$custom-control-indicator-checked-border-color: #879baa;

$theme-custom-switch-indicator-bg: $primary;

/** global settings **/
$font-size-base: 0.875rem; // We're aiming at ~14px with a browser default of ~16px
$table-bg-level: -11; //lighten table backgrounds

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-border-width: 2px;

$alert-border-radius: 0;

$input-btn-padding-y: 0.6rem;

/** theme specific **/
$theme-header-bg: $body-bg;
$theme-header-size: 100px;
$theme-footer-bg: #333353;
$theme-selected-bg: #e9ecef;
$theme-input-label-color: $input-placeholder-color;
$theme-pictogram-stroke: $input-placeholder-color;
$theme-unset: $input-placeholder-color;
$theme-badge-primary-accent-bg: #65c1bc;
$theme-edit-form-bg: #65c1bc0e;

$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-color: $theme-footer-bg;
$pagination-active-color: #fff;
$pagination-active-bg: $theme-footer-bg;
$pagination-disabled-bg: transparent;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 600px,
//   md: 768px,
//   lg: 1024px,
//   xl: 1366px,
// );

// $container-max-widths: (
//   sm: 600px * 0.925,
//   md: 768px * 0.925,
//   lg: 1024px * 0.925,
//   xl: 1366px * 0.925,
// );

// /** additional siemens colors **/
$activeBlue: #005578;
// $disabled: #dfe6ed;
// $disabledText: #9bafbe;

$stone: #2d373c;
// $stone2: #697882;
// $stone3: #788791;
// $stoneLight: #879baa;
// $stoneLight2: #dfe6ed;
// $boderLight: #cdd9e1;
// $accentBlue: #2387aa;
// $accentBlueDark: #00557d;
// $accentTeal5: #0f8287;
// $componentBG: #ebf0f5;
// $componentHoverBG: $stoneLight2;
// $sorange: #faa50a;
// $sorangeHover: #eb780a;

// $navbarBG: #fff;
// $navbarColor: $stone3;

// /* Overwrite Bootstrap default colors */
// $blue: $accentBlue !default;
// $red: #dc0000 !default;
// $body-bg: #fff;
// $body-color: $stone;
// $link-color: $blue;
// $link-hover-color: $activeBlue;
// $card-cap-bg: $componentBG;
// $card-cap-color: $blue;
// $card-bg: $componentBG;

// /* inputs */
// $input-disabled-bg: $disabled;
// $input-border-color: $boderLight;
// $input-focus-border-color: $activeBlue;
// $input-color: $body-color;
// $input-focus-box-shadow: none;

// /** overwrite navbar colors */
$navbar-light-color: $primary;
$navbar-light-hover-color: $activeBlue;
$navbar-light-active-color: $activeBlue;
$navbar-nav-link-padding-x: 1rem;

$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: quote('>') !default;

// /** Custom CSS variable mapping  and defaults **/
:root {
  //   --disabled-bg: #{$disabled};
  //   --disabled-text: #{$disabledText};
  --theme-primary-active: #{$activeBlue};
  //   --main-error: #{$red};
  //   --border-light: #{$boderLight};

  //   --radio-border: #{$stoneLight};
  //   --radio-background: #fff;
  //   --radio-checkmark: #fff;

  //   --checkbox-border: #{$stoneLight};
  //   --checkbox-background: #fff;
  //   --checkbox-checkmark: #fff;

  //   --card-cap-bg-hover: #{$componentHoverBG};
  //   --card-cap-color-hover: var(--primary-active);

  // --text-color: #{$stone};
  --theme-hover-transition-time: 0.5s;
}

// /** Overwrite global bootstraps theme with standard css **/
// input.form-control,
// textarea.form-control {
//   border-top-color: var(--radio-border);
// }

// .form-control:invalid,
// .form-control.is-invalid,
// .form-control.is-invalid:focus {
//   box-shadow: none !important; /** needed to set important to overwrite **/
//   background-image: none !important;
//   border-color: var(--main-error);
//   color: var(--main-error);
// }

// .btn.btn-primary:not(:disabled):hover,
// .btn.btn-outline-primary:not(:disabled):hover {
//   background-color: #{$accentBlueDark};
//   border-color: #{$activeBlue};
// }

// button.active {
//   color: $activeBlue;
// }

// .w-10 {
//   width: 10%;
// }

// .badge {
//   border-radius: $badge-border-radius;
// }

$modal-header-bg: $body-bg;
$modal-body-bg: #fff;
$modal-close-bg: $modal-body-bg;
$modal-backdrop-bg: rgba(0,0,0, .5);
