@use '../../../theme.scss' as t;

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: t.$modal-backdrop-bg;
  z-index: 5;
}

.modalOpen {
  overflow: hidden;
}

.container {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  outline: 0;
  z-index: 5;
}

.modal {
  background: t.$modal-body-bg;
  position: relative;
  margin: auto;
  border-radius: 8px;
}

.header {
  background: t.$modal-header-bg;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.title {
  align-self: center;
}

.closeButton {
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background-color: t.$modal-close-bg;
  :hover {
    cursor: pointer;
  }
}

.content {
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
