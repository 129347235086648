@use '../../../theme.scss' as t;

.dropdown {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px !important;
  padding-top: 0 !important;

  [aria-selected='true'] {
    background-color: t.$theme-selected-bg;
  }
}

.chevron::after {
  position: absolute;
  right: 1em;
  top: 47%;
  width: 0;
  height: 0;
  opacity: 0.5;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.slotRight {
  color: t.$theme-input-label-color;
  position: absolute;
  right: 2.4em;
  transform: translateY(50%);
  top: 0;
}

.label {
  bottom: 0;
  color: t.$theme-input-label-color;
  pointer-events: none;
  left: 0.75rem;
  margin: 0;
  position: absolute;
  transform: translateY(-5%);
  transform-origin: top left;
  top: 0.75rem;
}

.focused {
  animation-duration: 0.2s;
  animation-name: revealvalue;
  animation-fill-mode: forwards;
}

@keyframes revealvalue {
  from {
  }
  to {
    transform: translateY(-20%) scale(0.6);
  }
}
