@use '../../../theme.scss' as t;

.selected {
  background-color: t.$theme-selected-bg !important;
  color: t.$primary !important;
}

.label {
  bottom: 0;
  color: t.$theme-input-label-color;
  pointer-events: none;
  left: 0.75rem;
  margin: 0;
  position: absolute;
  transform: translateY(-5%);
  transform-origin: top left;
  top: 0.75rem;
}

.focused {
  animation-duration: 0.2s;
  animation-name: revealvalue;
  animation-fill-mode: forwards;
}

@keyframes revealvalue {
  from {
  }
  to {
    transform: translateY(-20%) scale(0.6);
  }
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
